import React, { Component } from 'react';
import Message from 'oxyrion-ui/lib/Message';
import styled from 'styled-components';
import { rem } from 'polished';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';

import { __ } from '../../Utils';
import PriceListForm from '../../Components/PriceListForm';

import { AnimatedFormMessageWrapper } from '../../Components/ReusableComponents';
import API2 from '../../API2';

const acceptedMimeTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/pdf',
];

const Wrapper = styled.div`
  box-sizing: border-box;
  height: ${rem(520)};
`;

class ModifyPriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      // descriptionValue: '',
      submitMessage: __('Vytvoriť cenník'),
      errors: [],
      uploadLoading: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      'drop',
      (e) => {
        e.preventDefault();
      },
      false,
    );
  }

  ondrop(e) {
    e.stopPropagation();
    e.preventDefault();
    this.onDragLeave();
    if (e.dataTransfer.files.length > 0) {
      const f = e.dataTransfer.files[0];
      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({ errors: [__('Zlý formát súboru.')] });
      } else {
        this.setState({
          file: f,
          fileValue: f.name,
          fileError: false,
          errors: [],
        });
      }
    }
  }

  ondrag() {
    this.dragWrapper.style.boxShadow = `inset 0 0 ${rem(10)} ${
      baseTheme.color.primary
    }`;
  }

  onDragLeave() {
    this.dragWrapper.style.boxShadow = `inset 0 0 ${rem(1)} transparent`;
  }
  nameOnChange(value) {
    if (value.length === 0) {
      this.setState({ nameValue: value, nameError: true });
    } else {
      this.setState({ nameValue: value, nameError: false });
    }
  }

  dateOnChange(value) {
    if (!value) {
      this.setState({ dateValue: value, dateError: true });
    } else {
      this.setState({ dateValue: value, dateError: false });
    }
  }

  descriptionOnChange(value) {
    this.setState({ descriptionValue: value });
  }

  fileOnChange(files) {
    if (files.length) {
      const f = files[0];

      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({ errors: [__('Zlý formát súboru.')] });
      } else {
        this.setState({
          fileValue: f.name,
          file: f,
          fileError: false,
          errors: [],
        });
      }
    }
  }

  removeFile() {
    this.setState({ fileValue: '', file: null, fileError: true });
  }

  async submit() {
    this.setState({ uploadLoading: true });
    try {
      if (this.checkData()) {
        const { firm, onSubmit } = this.props;
        const { nameValue, file, dateValue } = this.state;
        await API2.postPricelistAction(firm, {
          body: {
            file,
            name: nameValue,
            dateFrom: dateValue,
          },
        });
        onSubmit();
        this.setState({
          nameValue: '',
          dateValue: null,
          file: undefined,
          fileValue: '',
          uploadLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        errors: [__('Pri načítavaní dát sa vyskytla chyba')],
        uploadLoading: false,
      });
    }
  }

  checkData() {
    const { nameValue, dateValue, file } = this.state;
    if (nameValue && dateValue && file) {
      this.setState({ errors: [] });
      return true;
    }
    let errors = [];
    if (!nameValue) {
      this.setState({
        nameError: true,
      });
      errors = [...errors, __('Názov nie je zadaný.')];
    }
    if (!file) {
      this.setState({
        fileError: true,
      });
      errors = [...errors, __('Súbor nie je vybraný.')];
    }
    if (!dateValue) {
      this.setState({
        dateError: true,
      });
      errors = [...errors, __('Dátum nie je vybraný.')];
    }
    this.setState({ errors, uploadLoading: false });
    return false;
  }

  render() {
    const {
      nameValue,
      submitMessage,
      fileValue,
      dateValue,
      nameError,
      fileError,
      dateError,
      errors,
      uploadLoading,
    } = this.state;
    return (
      <Wrapper
        draggable
        innerRef={(ref) => {
          this.dragWrapper = ref;
        }}
        onDragOver={() => this.ondrag()}
        onDragLeave={() => this.onDragLeave()}
        onDrop={(e) => this.ondrop(e)}
      >
        <AnimatedFormMessageWrapper display={errors.length}>
          {errors.map((e) => (
            <Message key={e} error message={e} />
          ))}
        </AnimatedFormMessageWrapper>
        <PriceListForm
          nameValue={nameValue}
          nameOnChange={(value) => this.nameOnChange(value)}
          nameError={nameError}
          fileValue={fileValue}
          fileOnChange={(files) => this.fileOnChange(files)}
          dateValue={dateValue}
          dateOnChange={(value) => this.dateOnChange(value)}
          dateError={dateError}
          removeFile={() => this.removeFile()}
          fileError={fileError}
          submitMessage={submitMessage}
          onSubmit={(value) => this.submit(value)}
          onDrop={(files) => this.onDrop(files)}
          loading={uploadLoading}
        />
      </Wrapper>
    );
  }
}

export default ModifyPriceList;
