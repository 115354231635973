/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
     * Get informations about user.

     * @method
     * @param {object} parameters - method options and parameters
     */
  meAction(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists additionals.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} codelistId - ID od codelist

     */
  getCodelist(codelistId, parameters = {}) {
    let path = '/codelists/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{codelistId}', codelistId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists additionals.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} codelistId - ID od codelist

       * @param {} parameters.body - 
     */
  putMainCodelistAction(codelistId, parameters = {}) {
    let path = '/codelists/{codelistId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{codelistId}', codelistId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create codelist item in codelis.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postCodelistAction(parameters = {}) {
    let path = '/codelists/items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update codelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} itemId - ID od codelist item

       * @param {} parameters.body - 
     */
  putCodelistAction(itemId, parameters = {}) {
    let path = '/codelists/items/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{itemId}', itemId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete codelistItem.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} itemId - ID od codelist item

     */
  deleteCodelistAction(itemId, parameters = {}) {
    let path = '/codelists/items/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists by type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.entity - entity of codelists to find
     */
  getCodelistsAction(parameters = {}) {
    let path = '/codelists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['entity'] !== 'undefined') {
      queryParameters['entity'] = parameters['entity'];
    }

    queryParameters['entity'] = this.transformParameter(
      queryParameters['entity'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create codelist item in codelis.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postMainCodelistAction(parameters = {}) {
    let path = '/codelists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists colorcards.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsColorcardsAction(parameters = {}) {
    let path = '/codelist/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists toning system.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsToningSystemAction(parameters = {}) {
    let path = '/codelist/toning-systems';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists documentation.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsDocumentationAction(parameters = {}) {
    let path = '/codelist/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists product brands.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsBrandsAction(parameters = {}) {
    let path = '/codelist/brands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists product subbrands.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsSubbrandsAction(parameters = {}) {
    let path = '/codelist/subbrands';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists base.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsBaseAction(parameters = {}) {
    let path = '/codelist/base';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get codelists additionals.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCodelistsAdditionalsAction(parameters = {}) {
    let path = '/codelist/additionals';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get shade properties for codelist.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getShadePropertiesAction(parameters = {}) {
    let path = '/codelist/shade-properties';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get color groups for codelist.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getColorUnderGroupsAction(parameters = {}) {
    let path = '/codelist/color-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get categories.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.lang - Language of category
     */
  getCategoriesAction(chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} lang - Language shortcut

       * @param {} parameters.forced - 
       * @param {} parameters.body - 
     */
  postCategoryAction(chanelId, lang, parameters = {}) {
    let path = '/admin/{chanelId}/categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{lang}', lang);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update categories structure.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} parameters.lang - Language of category
       * @param {} parameters.body - 
     */
  putCategoriesStructureAction(chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories-structure';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get categories tree.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.chanelId - 
       * @param {} parameters.lang - 
     */
  getCategoriesTreeAction(parameters = {}) {
    let path = '/admin/categories-tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['chanelId'] !== 'undefined') {
      queryParameters['chanelId'] = parameters['chanelId'];
    }

    queryParameters['chanelId'] = this.transformParameter(
      queryParameters['chanelId'],
    );

    if (typeof parameters['chanelId'] === 'undefined') {
      throw Error('Missing required parameter: chanelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} categoryId - ID of category

     */
  getCategoryAction(chanelId, categoryId, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{categoryId}', categoryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

       * @param {} chanelId - ID of chanel

       * @param {} parameters.forced - 
       * @param {} parameters.body - 
     */
  putCategoryAction(categoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

       * @param {} chanelId - ID of chanel

       * @param {} parameters.forced - 
     */
  deleteCategoryAction(categoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Inspiration images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

       * @param {} chanelId - ID of chanel

     */
  getCategoryImageAction(categoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    path = path.replace('{chanelId}', chanelId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create category image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

       * @param {} chanelId - ID of chanel

       * @param {} parameters.body - 
     */
  postCategoryImageAction(categoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete existing category image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} categoryId - ID of category

       * @param {} fullPath - File path

     */
  deleteCategoryImageAction(chanelId, categoryId, fullPath, parameters = {}) {
    let path = '/admin/{chanelId}/categories/{categoryId}/image/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{categoryId}', categoryId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Check if category url already exists.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} url - Url of category

       * @param {} parameters.chanelId - ID of chanel 

       * @param {} parameters.lang - Language of category
     */
  checkCategoryUrlAction(url, parameters = {}) {
    let path = '/admin/categories-url-check/{url}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{url}', url);

    if (typeof parameters['chanelId'] !== 'undefined') {
      queryParameters['chanelId'] = parameters['chanelId'];
    }

    queryParameters['chanelId'] = this.transformParameter(
      queryParameters['chanelId'],
    );

    if (typeof parameters['chanelId'] === 'undefined') {
      throw Error('Missing required parameter: chanelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw Error('Missing required parameter: lang');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get color categories.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getColorCategoriesAction(parameters = {}) {
    let path = '/admin/{chanelId}/color-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new color category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postColorCategoryAction(parameters = {}) {
    let path = '/admin/{chanelId}/color-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get color categories tree.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getColorCategoriesTreeAction(parameters = {}) {
    let path = '/admin/color-categories-tree';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of color category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

     */
  getColorCategoryAction(categoryId, parameters = {}) {
    let path = '/admin/{chanelId}/color-categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update color category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

       * @param {} parameters.body - 
     */
  putColorCategoryAction(categoryId, parameters = {}) {
    let path = '/admin/{chanelId}/color-categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete color category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - ID of category

     */
  deleteColorCategoryAction(categoryId, parameters = {}) {
    let path = '/admin/{chanelId}/color-categories/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get favourite categories.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} parameters.q - Quicksearch by a product number or a name
       * @param {} parameters.lang - Language of favorite category
     */
  getFavoriteCategoriesAction(chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/favorite-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new favorite category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} lang - Language shortcut

       * @param {} parameters.body - 
     */
  postFavoriteCategoryAction(chanelId, lang, parameters = {}) {
    let path = '/admin/{chanelId}/favorite-categories';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{lang}', lang);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of a favorite category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} favoriteCategoryId - ID of category

     */
  getFavoriteCategoryAction(chanelId, favoriteCategoryId, parameters = {}) {
    let path = '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{favoriteCategoryId}', favoriteCategoryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update a favorite category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} favoriteCategoryId - ID of category

       * @param {} chanelId - ID of chanel

       * @param {} parameters.body - 
     */
  putFavoriteCategoryAction(favoriteCategoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{favoriteCategoryId}', favoriteCategoryId);

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete a favorite category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} favoriteCategoryId - ID of category

       * @param {} chanelId - ID of chanel

     */
  deleteFavoriteCategoryAction(favoriteCategoryId, chanelId, parameters = {}) {
    let path = '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{favoriteCategoryId}', favoriteCategoryId);

    path = path.replace('{chanelId}', chanelId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Inspiration images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} categoryId - ID of category

     */
  getFavoriteCategoryImageAction(chanelId, categoryId, parameters = {}) {
    let path =
      '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{categoryId}', categoryId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create category image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} favoriteCategoryId - ID of category

       * @param {} chanelId - ID of chanel

       * @param {} parameters.body - 
     */
  postFavoriteCategoryImageAction(
    favoriteCategoryId,
    chanelId,
    parameters = {},
  ) {
    let path =
      '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{favoriteCategoryId}', favoriteCategoryId);

    path = path.replace('{chanelId}', chanelId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete existing category image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} chanelId - ID of chanel

       * @param {} favoriteCategoryId - ID of category

       * @param {} fullPath - File path

     */
  deleteFavoriteCategoryImageAction(
    chanelId,
    favoriteCategoryId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/admin/{chanelId}/favorite-categories/{favoriteCategoryId}/image/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{chanelId}', chanelId);

    path = path.replace('{favoriteCategoryId}', favoriteCategoryId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product types.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.onlyProductTypes - return only types for products
     */
  getProductTypesAction(parameters = {}) {
    let path = '/admin/product-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['onlyProductTypes'] = true;

    if (typeof parameters['onlyProductTypes'] !== 'undefined') {
      queryParameters['onlyProductTypes'] = parameters['onlyProductTypes'];
    }

    queryParameters['onlyProductTypes'] = this.transformParameter(
      queryParameters['onlyProductTypes'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postProductTypeAction(parameters = {}) {
    let path = '/admin/product-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

     */
  getProductTypeAction(typeId, parameters = {}) {
    let path = '/admin/product-types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} parameters.body - 
     */
  putProductTypeAction(typeId, parameters = {}) {
    let path = '/admin/product-types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete product type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

     */
  deleteProductTypeAction(typeId, parameters = {}) {
    let path = '/admin/product-types/{typeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type params.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

     */
  getProductTypeParamsAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/params';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create codelist item in codelis.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} parameters.body - 
     */
  postProductTypeParamAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/params';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update params and variant params for farlesk export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsParamsExportFarleskAction(parameters = {}) {
    let path = '/admin/farleskt-params-edit';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for Obnova export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsExportObnovaAction(parameters = {}) {
    let path = '/admin/obnova-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for farlesk export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsExportFarleskAction(parameters = {}) {
    let path = '/admin/farlesk-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for kopelent export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsExportKopelentAction(parameters = {}) {
    let path = '/admin/kopelent-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for SEKO export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsSEKOExportAction(parameters = {}) {
    let path = '/admin/seko-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for Homola export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsHomolaExportAction(parameters = {}) {
    let path = '/admin/homola-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products and variant for meta pixel export.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsMetaPixelExportAction(parameters = {}) {
    let path = '/admin/meta-pixel-product';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type params.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getProductTypesAllParamsAction(parameters = {}) {
    let path = '/admin/attribs/all-params';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create codelist item in codelis.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} parameters.body - 
     */
  postProductTypeParamAction_1(typeId, parameters = {}) {
    let path = '/admin/attribs/all-params';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update codelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} paramId - ID of param

       * @param {} parameters.body - 
     */
  putProductTypeParamAction(typeId, paramId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/params/{paramId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{paramId}', paramId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete param.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} paramId - ID of param

       * @param {} parameters.forced - 
     */
  deleteProductTypeParamAction(typeId, paramId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/params/{paramId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{paramId}', paramId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type params.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

     */
  getProductTypeVariantParamsAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create codelist item in codelis.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} parameters.body - 
     */
  postProductTypeVariantsParamAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update codelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} paramId - ID of param

       * @param {} parameters.body - 
     */
  putProductTypeVariantParamAction(typeId, paramId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/variants/{paramId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{paramId}', paramId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete param.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} paramId - ID of param

       * @param {} parameters.forced - 
     */
  deleteProductTypeVariantParamAction(typeId, paramId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/variants/{paramId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{paramId}', paramId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.sorter - Sorter
     */
  getPaintSystemsAction(parameters = {}) {
    let path = '/admin/paintSystem';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create paint system.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postPaintSystemAction(parameters = {}) {
    let path = '/admin/paintSystem';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system code detail.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getPaintSystemNextCodeAction(parameters = {}) {
    let path = '/admin/paintSystem-newCode';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paintSystemId - ID od product

     */
  getPaintSystemAction(paintSystemId, parameters = {}) {
    let path = '/admin/paintSystem/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update paint system.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paintSystemId - ID od product

       * @param {} parameters.body - 
     */
  putPaintSystemAction(paintSystemId, parameters = {}) {
    let path = '/admin/paintSystem/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete paint system.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paintSystemId - ID od product

     */
  deletePaintSystemAction(paintSystemId, parameters = {}) {
    let path = '/admin/paintSystem/{paintSystemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get paint system detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paintSystemId - ID od product

     */
  getPaintSystemCopyAction(paintSystemId, parameters = {}) {
    let path = '/admin/paintSystem/{paintSystemId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{paintSystemId}', paintSystemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  getProductAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putProductAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  deleteProductAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products eshop names.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getCatalogProductVariantsAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/products/eshop-names';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product eshop names.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} catalogProductId - ID od catalog product

       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductVariantsAction(
    companyId,
    catalogProductId,
    productId,
    parameters = {},
  ) {
    let path =
      '/admin/{companyId}/products/eshop-names/{catalogProductId}/{productId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{catalogProductId}', catalogProductId);

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductPreferredOrder(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/order';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add product publication.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} categoryId - ID of category

       * @param {} parameters.channelId - Id of channel
       * @param {} parameters.lang - language
     */
  putProductPublicationAction(productId, categoryId, parameters = {}) {
    let path =
      '/admin/catalogProducts/{productId}/categoryPublication/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{categoryId}', categoryId);

    if (typeof parameters['channelId'] !== 'undefined') {
      queryParameters['channelId'] = parameters['channelId'];
    }

    queryParameters['channelId'] = this.transformParameter(
      queryParameters['channelId'],
    );

    if (typeof parameters['channelId'] === 'undefined') {
      throw Error('Missing required parameter: channelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw Error('Missing required parameter: lang');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add product publication.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} categoryId - ID of category

       * @param {} parameters.channelId - Id of channel
       * @param {} parameters.lang - language
     */
  deleteProductPublicationAction(productId, categoryId, parameters = {}) {
    let path =
      '/admin/catalogProducts/{productId}/categoryPublication/{categoryId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{categoryId}', categoryId);

    if (typeof parameters['channelId'] !== 'undefined') {
      queryParameters['channelId'] = parameters['channelId'];
    }

    queryParameters['channelId'] = this.transformParameter(
      queryParameters['channelId'],
    );

    if (typeof parameters['channelId'] === 'undefined') {
      throw Error('Missing required parameter: channelId');
    }

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw Error('Missing required parameter: lang');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product copy in product catalog.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  postProductCopyAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product extended description.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putProductExtendedDescription(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/extended-description';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get catalog product sales.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  getCatalogProductSalesAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get favourite categories.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
     */
  getShopsAction(parameters = {}) {
    let path = '/admin/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postShopAction(parameters = {}) {
    let path = '/admin/shops';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of a shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
     */
  getShopAction(shopId, parameters = {}) {
    let path = '/admin/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update a shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
       * @param {} parameters.body - 
     */
  putShopAction(shopId, parameters = {}) {
    let path = '/admin/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete a shop.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
     */
  deleteShopAction(shopId, parameters = {}) {
    let path = '/admin/shop/{shopId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Shop images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
     */
  getShopImagesAction(shopId, parameters = {}) {
    let path = '/admin/shop/{shopId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create shop image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
       * @param {} parameters.body - 
     */
  postShopImageAction(shopId, parameters = {}) {
    let path = '/admin/shop/{shopId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete existing shop image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shopId - ID of a shop
       * @param {} fullPath - File path

     */
  deleteShopImageAction(shopId, fullPath, parameters = {}) {
    let path = '/admin/shop/{shopId}/image/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shopId}', shopId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get lisit of banners.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by name
       * @param {} parameters.publishedApps - search by published apps
       * @param {} parameters.country - search by country
       * @param {} parameters.sorter - Sorter
     */
  getBannersAction(pageId, parameters = {}) {
    let path = '/admin/banners/{pageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['publishedApps'] !== 'undefined') {
      queryParameters['publishedApps'] = parameters['publishedApps'];
    }

    queryParameters['publishedApps'] = this.transformParameter(
      queryParameters['publishedApps'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new Banner.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} parameters.force - force
       * @param {} parameters.body - 
     */
  postBannerAction(pageId, parameters = {}) {
    let path = '/admin/banners/{pageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    if (typeof parameters['force'] !== 'undefined') {
      queryParameters['force'] = parameters['force'];
    }

    queryParameters['force'] = this.transformParameter(
      queryParameters['force'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of a banner.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} bannerId - ID of a banner
     */
  getBannerAction(pageId, bannerId, parameters = {}) {
    let path = '/admin/banners/{pageId}/{bannerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{bannerId}', bannerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update a banner.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} bannerId - ID of a banner
       * @param {} parameters.force - force
       * @param {} parameters.body - 
     */
  putBannerAction(pageId, bannerId, parameters = {}) {
    let path = '/admin/banners/{pageId}/{bannerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{bannerId}', bannerId);

    if (typeof parameters['force'] !== 'undefined') {
      queryParameters['force'] = parameters['force'];
    }

    queryParameters['force'] = this.transformParameter(
      queryParameters['force'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete a banner.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} bannerId - ID of a banner
     */
  deleteBannerAction(pageId, bannerId, parameters = {}) {
    let path = '/admin/banners/{pageId}/{bannerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{bannerId}', bannerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Banner images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} bannerId - ID of a banner
       * @param {} parameters.mobile - Mobile image
     */
  getBannerImageAction(pageId, bannerId, parameters = {}) {
    let path = '/admin/banners/{pageId}/{bannerId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{bannerId}', bannerId);

    if (typeof parameters['mobile'] !== 'undefined') {
      queryParameters['mobile'] = parameters['mobile'];
    }

    queryParameters['mobile'] = this.transformParameter(
      queryParameters['mobile'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create banner image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pageId - 
       * @param {} bannerId - ID of a banner
       * @param {} parameters.mobile - Mobile image
       * @param {} parameters.body - 
     */
  postBannerImageAction(pageId, bannerId, parameters = {}) {
    let path = '/admin/banners/{pageId}/{bannerId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{pageId}', pageId);

    path = path.replace('{bannerId}', bannerId);

    if (typeof parameters['mobile'] !== 'undefined') {
      queryParameters['mobile'] = parameters['mobile'];
    }

    queryParameters['mobile'] = this.transformParameter(
      queryParameters['mobile'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get array of products packages.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch for package products.
       * @param {} parameters.active - Filtering products packages according to active status (from dates of publication).
       * @param {} parameters.sorter - Sorter
     */
  getProductsPackagesAction(parameters = {}) {
    let path = '/admin/products-packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create a new package.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postProductsPackageAction(parameters = {}) {
    let path = '/admin/products-packages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get array of products package.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
     */
  getProductsPackageAction(productsPackageId, parameters = {}) {
    let path = '/admin/products-packages/{productsPackageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete products package.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
     */
  deleteProductsPackageAction(productsPackageId, parameters = {}) {
    let path = '/admin/products-packages/{productsPackageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update existing package

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
       * @param {} parameters.body - 
     */
  putProductsPackageAction(productsPackageId, parameters = {}) {
    let path = '/admin/products-packages/{productsPackageId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get ProductsPackage images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
     */
  getProductsPackageImageAction(productsPackageId, parameters = {}) {
    let path = '/admin/products-packages/{productsPackageId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create products package image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
       * @param {} parameters.body - 
     */
  postProductsPackageImageAction(productsPackageId, parameters = {}) {
    let path = '/admin/products-packages/{productsPackageId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete existing package image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productsPackageId - ID of productsPackage
       * @param {} fullPath - File path

     */
  deleteProductsPackageImageAction(
    productsPackageId,
    fullPath,
    parameters = {},
  ) {
    let path = '/admin/products-packages/{productsPackageId}/image/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productsPackageId}', productsPackageId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductToningSystemAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/toningSystem';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} colorcardId - ID od colorcard

       * @param {} parameters.body - 
     */
  putCatalogProductColocardPurposeAction(
    productId,
    colorcardId,
    parameters = {},
  ) {
    let path =
      '/admin/catalogProducts/{productId}/colorcards/{colorcardId}/purpose';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductColocardAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/colorcards-add';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new product margin.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  postCatalogProductMarginAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/margin';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product margins.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  getCatalogProductMarginsAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/margin';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete margin in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} customerId - ID od customer

     */
  deleteMarginAction(productId, customerId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/margin/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get filter of catalog products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.publicationChannel - Publication channel
       * @param {} parameters.sorter - Sorter
     */
  getFiltersAction(parameters = {}) {
    let path = '/admin/catalogProducts/filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['publicationChannel'] !== 'undefined') {
      queryParameters['publicationChannel'] = parameters['publicationChannel'];
    }

    queryParameters['publicationChannel'] = this.transformParameter(
      queryParameters['publicationChannel'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create filter for catalog product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postFilterAction(parameters = {}) {
    let path = '/admin/catalogProducts/filters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get filter detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} filterId - ID od filter

     */
  getFilterAction(filterId, parameters = {}) {
    let path = '/admin/catalogProducts/filters/{filterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{filterId}', filterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} filterId - ID od filter

       * @param {} parameters.body - 
     */
  putFilterAction(filterId, parameters = {}) {
    let path = '/admin/catalogProducts/filters/{filterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{filterId}', filterId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete filter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} filterId - ID od filter

     */
  deleteFilterAction(filterId, parameters = {}) {
    let path = '/admin/catalogProducts/filters/{filterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{filterId}', filterId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.hasAllImages - Filter for images in products separation
       * @param {} parameters.hasAllVariants - Filter for variants in products separation
       * @param {} parameters.hasCompleteDocumentation - Filter for documentation in products
       * @param {} parameters.published - Filter products according to publication
       * @param {} parameters.lang - Filter products according to lang
       * @param {} parameters.type - Type of product
       * @param {} parameters.brand - Brand of product
       * @param {} parameters.channelId - Filter for publication channel
       * @param {} parameters.category - Category of product
       * @param {} parameters.sorter - Sorter
     */
  getCatalogProductsAction(parameters = {}) {
    let path = '/admin/catalogProducts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['hasAllImages'] !== 'undefined') {
      queryParameters['has_all_images'] = parameters['hasAllImages'];
    }

    queryParameters['has_all_images'] = this.transformParameter(
      queryParameters['has_all_images'],
    );

    if (typeof parameters['hasAllVariants'] !== 'undefined') {
      queryParameters['has_all_variants'] = parameters['hasAllVariants'];
    }

    queryParameters['has_all_variants'] = this.transformParameter(
      queryParameters['has_all_variants'],
    );

    if (typeof parameters['hasCompleteDocumentation'] !== 'undefined') {
      queryParameters['has_complete_documentation'] =
        parameters['hasCompleteDocumentation'];
    }

    queryParameters['has_complete_documentation'] = this.transformParameter(
      queryParameters['has_complete_documentation'],
    );

    if (typeof parameters['published'] !== 'undefined') {
      queryParameters['published'] = parameters['published'];
    }

    queryParameters['published'] = this.transformParameter(
      queryParameters['published'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['channelId'] !== 'undefined') {
      queryParameters['channelId'] = parameters['channelId'];
    }

    queryParameters['channelId'] = this.transformParameter(
      queryParameters['channelId'],
    );

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product in product catalog.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postProductAction(parameters = {}) {
    let path = '/admin/catalogProducts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get all products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.brand - Filter by brand
       * @param {} parameters.catalogProductId - Filter by catalog product
       * @param {} parameters.type - Filter by catalog product type
     */
  getAllCatalogProductsAction(parameters = {}) {
    let path = '/admin/allCatalogProducts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['sorter'] = '-name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['brand'] !== 'undefined') {
      queryParameters['brand'] = parameters['brand'];
    }

    queryParameters['brand'] = this.transformParameter(
      queryParameters['brand'],
    );

    if (typeof parameters['catalogProductId'] !== 'undefined') {
      queryParameters['catalogProductId'] = parameters['catalogProductId'];
    }

    queryParameters['catalogProductId'] = this.transformParameter(
      queryParameters['catalogProductId'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List documents linked to product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  getProductDocumentationAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create document in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  postProductDocumentationAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/documentation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update document in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} documentId - ID of product

       * @param {} parameters.body - 
     */
  putProductDocumentationAction(productId, documentId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/documentation/{documentId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{documentId}', documentId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete document in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} documentId - ID of product

     */
  deleteProductDocumentationAction(productId, documentId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/documentation/{documentId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{documentId}', documentId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List product images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

     */
  getProductImagesAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  postProductImageAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductImage(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete document in product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} fullPath - File path

     */
  deleteProductImageAction(productId, fullPath, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List product variant images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} variantId - 
     */
  getProductVariantImagesAction(productId, variantId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/variants/{variantId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{variantId}', variantId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in product variant.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} variantId - 
       * @param {} parameters.body - 
     */
  postProductVariantImageAction(productId, variantId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/variants/{variantId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{variantId}', variantId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete product variant images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} variantId - 
       * @param {} fullPath - File path

     */
  deleteProductVariantImageAction(
    productId,
    variantId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/admin/catalogProducts/{productId}/variants/{variantId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    path = path.replace('{variantId}', variantId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get colorcards for catalog products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.toningSystemId - Id of tonining system
     */
  getCatalogProductColorcardsAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/colorcards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['toningSystemId'] !== 'undefined') {
      queryParameters['toningSystemId'] = parameters['toningSystemId'];
    }

    queryParameters['toningSystemId'] = this.transformParameter(
      queryParameters['toningSystemId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get colorcards for catalog products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.chanel - publication chanel
       * @param {} parameters.externalProducts - ids of external products
     */
  getCatalogProductColorcardsPublicationAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/colorcards-publication';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['chanel'] !== 'undefined') {
      queryParameters['chanel'] = parameters['chanel'];
    }

    queryParameters['chanel'] = this.transformParameter(
      queryParameters['chanel'],
    );

    if (typeof parameters['externalProducts'] !== 'undefined') {
      queryParameters['externalProducts'] = parameters['externalProducts'];
    }

    queryParameters['externalProducts'] = this.transformParameter(
      queryParameters['externalProducts'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update colorcards pulication.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.body - 
     */
  putCatalogProductColorcardsPublicationAction(productId, parameters = {}) {
    let path = '/admin/catalogProducts/{productId}/colorcards-publication';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get catalog product reviews.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} catalogProductId - ID od catalog product

       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.sorter - Sorter
     */
  getCatalogProductsReviewsAction(
    companyId,
    catalogProductId,
    parameters = {},
  ) {
    let path = '/admin/{companyId}/catalogProducts/{catalogProductId}/reviews';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{catalogProductId}', catalogProductId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type groups.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

     */
  getProductTypeGroupsAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product type group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} parameters.body - 
     */
  postProductTypeGroupAction(typeId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product type group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} groupId - ID of product type group

     */
  getProductTypeGroupAction(typeId, groupId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/groups/{groupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{groupId}', groupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product type group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} groupId - ID of product type group

       * @param {} parameters.body - 
     */
  putProductTypeGroupAction(typeId, groupId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/groups/{groupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{groupId}', groupId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete product type group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} typeId - ID of product type

       * @param {} groupId - ID of product type group

     */
  deleteProductTypeGroupAction(typeId, groupId, parameters = {}) {
    let path = '/admin/attribs/{typeId}/groups/{groupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{typeId}', typeId);

    path = path.replace('{groupId}', groupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing lists.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by various criteria
       * @param {} parameters.firm - firm of marketing list
       * @param {} parameters.sorter - Sorter
     */
  getMarketingListsAction(parameters = {}) {
    let path = '/admin/marketing-lists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create marketing list with base informations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postMarketingListAction(parameters = {}) {
    let path = '/admin/marketing-lists';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

     */
  getMarketingListAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} parameters.body - 
     */
  putMarketingListAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

     */
  deleteMarketingListAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add contact to marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} contactId - ID of contact

     */
  postMarketingListContactAction(marketingListId, contactId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/contacts/{contactId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{contactId}', contactId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add user to marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} userId - ID of user

     */
  postMarketingListUserAction(marketingListId, userId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete user from marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} userId - ID of user

     */
  deleteMarketingListUserAction(marketingListId, userId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add customer contacts to marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} customerId - ID od customer

     */
  postMarketingListCustomerAction(
    marketingListId,
    customerId,
    parameters = {},
  ) {
    let path =
      '/admin/marketing-lists/{marketingListId}/customers/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get customer types lists.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by various criteria
       * @param {} parameters.sorter - Sorter
     */
  getCustomerTypesAction(parameters = {}) {
    let path = '/admin/customer-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create customer type with base informations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postCustomerTypeAction(parameters = {}) {
    let path = '/admin/customer-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get customer type details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerTypeId - ID of customer type

     */
  getCustomerTypeAction(customerTypeId, parameters = {}) {
    let path = '/admin/customer-types/{customerTypeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerTypeId}', customerTypeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update customer type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerTypeId - ID of customer type

       * @param {} parameters.body - 
     */
  putCustomerTypeAction(customerTypeId, parameters = {}) {
    let path = '/admin/customer-types/{customerTypeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customerTypeId}', customerTypeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete customer type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerTypeId - ID of customer type

     */
  deleteCustomerTypeAction(customerTypeId, parameters = {}) {
    let path = '/admin/customer-types/{customerTypeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerTypeId}', customerTypeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by various criteria
       * @param {} parameters.sorter - Sorter
     */
  getMarketingListContactsAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/contacts-list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} parameters.body - 
     */
  postMarketingListContactsAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/contacts-list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update marketing list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} parameters.forced - Forced update
       * @param {} parameters.body - 
     */
  putMarketingListContactsAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/contacts-list';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    if (typeof parameters['forced'] !== 'undefined') {
      queryParameters['forced'] = parameters['forced'];
    }

    queryParameters['forced'] = this.transformParameter(
      queryParameters['forced'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing list contacts excel.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

     */
  exportMarketingListContactsAction(marketingListId, parameters = {}) {
    let path = '/admin/marketing-lists/{marketingListId}/contacts-list-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update contact active field.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingListId - ID of marketing list

       * @param {} contactId - ID of contact

       * @param {} parameters.body - 
     */
  putMarketingListContactStatusAction(
    marketingListId,
    contactId,
    parameters = {},
  ) {
    let path =
      '/admin/marketing-lists/{marketingListId}/contacts-list/{contactId}/updateStatus';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingListId}', marketingListId);

    path = path.replace('{contactId}', contactId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get marketing materials list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.category - Category of marketing material
       * @param {} parameters.sorter - Sorter
     */
  getMarketingMaterialsAction(parameters = {}) {
    let path = '/admin/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create marketing material.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postMarketingMaterialAction(parameters = {}) {
    let path = '/admin/marketing-materials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get material detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

     */
  getMarketingMaterialAction(marketingMaterialId, parameters = {}) {
    let path = '/admin/marketing-materials/{marketingMaterialId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update marketing material.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

       * @param {} parameters.body - 
     */
  putMarketingMaterialAction(marketingMaterialId, parameters = {}) {
    let path = '/admin/marketing-materials/{marketingMaterialId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete marketing material.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

     */
  deleteMarketingMaterialAction(marketingMaterialId, parameters = {}) {
    let path = '/admin/marketing-materials/{marketingMaterialId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * List of marketing material images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

     */
  getMarketingMaterialImagesAction(marketingMaterialId, parameters = {}) {
    let path = '/admin/marketing-materials/{marketingMaterialId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in marketing material.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

       * @param {} parameters.body - 
     */
  postMarketingMaterialImageAction(marketingMaterialId, parameters = {}) {
    let path = '/admin/marketing-materials/{marketingMaterialId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete file in marketing materials.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} marketingMaterialId - ID of marketing material

       * @param {} lang - Language shortcut

     */
  deleteMarketingMaterialsImageAction(
    marketingMaterialId,
    lang,
    parameters = {},
  ) {
    let path = '/admin/marketing-materials/{marketingMaterialId}/images/{lang}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{marketingMaterialId}', marketingMaterialId);

    path = path.replace('{lang}', lang);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get graphic, print, video list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.category - Filter by  category
       * @param {} parameters.type - Filter by type
       * @param {} parameters.sorter - Sorter
     */
  getGraphicsPrintsVideosAction(parameters = {}) {
    let path = '/admin/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['category'] !== 'undefined') {
      queryParameters['category'] = parameters['category'];
    }

    queryParameters['category'] = this.transformParameter(
      queryParameters['category'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create GPV material.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postGraphicPrintVideoAction(parameters = {}) {
    let path = '/admin/multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get material detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

     */
  getGraphicPrintVideoAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

       * @param {} parameters.body - 
     */
  putGraphicPrintVideoAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

     */
  deleteGraphicPrintVideoAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create FILE in Multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

       * @param {} parameters.body - 
     */
  postYoutubeVideoAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}/youtube-video';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create FILE in Multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

       * @param {} parameters.body - 
     */
  postMultimediaFileAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete file in multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} multimediaId - ID of multimedia

       * @param {} parameters.lang - Lang of file
       * @param {} parameters.type - Type of file
     */
  deleteMultimediaFileAction(multimediaId, parameters = {}) {
    let path = '/admin/multimedia/{multimediaId}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{multimediaId}', multimediaId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get file to download by downloadId.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} downloadId - 
     */
  getDownloaderAction(downloadId, parameters = {}) {
    let path = '/downloader/{downloadId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{downloadId}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} size - 
       * @param {} fullPath - File path

     */
  getImageAction(size, fullPath, parameters = {}) {
    let path = '/images/{size}/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{size}', size);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} fullPath - File path

     */
  getPublicFileAction(fullPath, parameters = {}) {
    let path = '/public/file/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get assigns of inovatint products to catalog products.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getInnovatintProductsAssignsAction(parameters = {}) {
    let path = '/admin/innovatint-products-assigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update assigns of inovatint products to catalog products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postInnovatintProductsAssignsAction(parameters = {}) {
    let path = '/admin/innovatint-products-assigns';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
     */
  getInnovatintShadePropertiesAction(parameters = {}) {
    let path = '/admin/innovatint/shade-properties';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create shade property.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postInnovatintShadePropertyAction(parameters = {}) {
    let path = '/admin/innovatint/shade-properties';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inovatint shade property detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shadePropertyId - ID of shade property

     */
  getInnovatintShadePropertyAction(shadePropertyId, parameters = {}) {
    let path = '/admin/innovatint/shade-properties/{shadePropertyId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shadePropertyId}', shadePropertyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update shade property.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shadePropertyId - ID of shade property

       * @param {} parameters.body - 
     */
  putInnovatintShadePropertyAction(shadePropertyId, parameters = {}) {
    let path = '/admin/innovatint/shade-properties/{shadePropertyId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{shadePropertyId}', shadePropertyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete shade property.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} shadePropertyId - ID of shade property

     */
  deleteInnovatintShadePropertyAction(shadePropertyId, parameters = {}) {
    let path = '/admin/innovatint/shade-properties/{shadePropertyId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{shadePropertyId}', shadePropertyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.sorter - Sorter
     */
  getBonusesAction(parameters = {}) {
    let path = '/admin/bonus';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create bonuse.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postBonusAction(parameters = {}) {
    let path = '/admin/bonus';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get bonus detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} bonusId - ID of bonus

     */
  getBonusAction(bonusId, parameters = {}) {
    let path = '/admin/bonuses/{bonusId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{bonusId}', bonusId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update bonus.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} bonusId - ID of bonus

       * @param {} parameters.body - 
     */
  putBonusAction(bonusId, parameters = {}) {
    let path = '/admin/bonuses/{bonusId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{bonusId}', bonusId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete bonus.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} bonusId - ID of bonus

     */
  deleteBonusAction(bonusId, parameters = {}) {
    let path = '/admin/bonuses/{bonusId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{bonusId}', bonusId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get translations list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by trans by app or lang
       * @param {} parameters.sorter - Sorter
     */
  getTranslationsAction(parameters = {}) {
    let path = '/admin/translations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create trasnlation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postTranslationAction(parameters = {}) {
    let path = '/admin/translations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get transaltion detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appTranslationId - ID od translations

     */
  getTranslationAction(appTranslationId, parameters = {}) {
    let path = '/admin/translations/{appTranslationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appTranslationId}', appTranslationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update translation.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appTranslationId - ID od translations

       * @param {} parameters.body - 
     */
  putTranslationAction(appTranslationId, parameters = {}) {
    let path = '/admin/translations/{appTranslationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appTranslationId}', appTranslationId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get report taks.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.sorter - Sorter
     */
  getReportTasksAction(parameters = {}) {
    let path = '/admin/reports-tasks';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create report task.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postReportTaskAction(parameters = {}) {
    let path = '/admin/reports-tasks';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get report task detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} reportTaskId - ID of report task

     */
  getReportTaskAction(reportTaskId, parameters = {}) {
    let path = '/admin/reports-tasks/{reportTaskId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{reportTaskId}', reportTaskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update report task.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} reportTaskId - ID of report task

       * @param {} parameters.body - 
     */
  putReportTaskAction(reportTaskId, parameters = {}) {
    let path = '/admin/reports-tasks/{reportTaskId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{reportTaskId}', reportTaskId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete report task.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} reportTaskId - ID of report task

     */
  deleteReportTaskAction(reportTaskId, parameters = {}) {
    let path = '/admin/reports-tasks/{reportTaskId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{reportTaskId}', reportTaskId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of newsletters.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} companyId - Shortcut of location
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quickseach by various criteria
       * @param {} parameters.status - search by status
       * @param {} parameters.sorter - Sorter
     */
  getNewslettersAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['status'] !== 'undefined') {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters['status'] = this.transformParameter(
      queryParameters['status'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.body - 
     */
  postNewsletterAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of newsletters tags.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.q - Qickseach by various criteria
     */
  getNewslettersTagsAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters-tags';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create copy of newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
       * @param {} parameters.forCompanyId - Firm of new newsletter
       * @param {} parameters.isLangCopy - Firm of new newsletter
     */
  postNewsletterCopyAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters-copy/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    queryParameters['forCompanyId'] = 'SK';

    if (typeof parameters['forCompanyId'] !== 'undefined') {
      queryParameters['forCompanyId'] = parameters['forCompanyId'];
    }

    queryParameters['forCompanyId'] = this.transformParameter(
      queryParameters['forCompanyId'],
    );

    if (typeof parameters['isLangCopy'] !== 'undefined') {
      queryParameters['isLangCopy'] = parameters['isLangCopy'];
    }

    queryParameters['isLangCopy'] = this.transformParameter(
      queryParameters['isLangCopy'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get newsletter detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
     */
  getNewsletterAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
       * @param {} parameters.body - 
     */
  putNewsletterAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
     */
  deleteNewsletterAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
     */
  sendNewsletterTestEmailAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters-send-test-email/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update newsletter.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} companyId - Shortcut of location
     */
  sendNewsletterEmailAction(newsletterId, companyId, parameters = {}) {
    let path = '/admin/{companyId}/newsletters-send-email/{newsletterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create newsletter attachment.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} parameters.body - 
     */
  postNewsletterAttachmentAction(newsletterId, parameters = {}) {
    let path = '/admin/newsletter/{newsletterId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete newsletter attachment

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsletterId - ID of newsletter

       * @param {} fullPath - File path

     */
  deleteNewsletterAttachmentAction(newsletterId, fullPath, parameters = {}) {
    let path = '/admin/newsletter/{newsletterId}/attachments/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsletterId}', newsletterId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create article in news section.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postNewsAction(parameters = {}) {
    let path = '/admin/news';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by a product number or name
       * @param {} parameters.articleType - Type
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.filter - Filter
     */
  getNewsListAction(parameters = {}) {
    let path = '/admin/news';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['articleType'] !== 'undefined') {
      queryParameters['articleType'] = parameters['articleType'];
    }

    queryParameters['articleType'] = this.transformParameter(
      queryParameters['articleType'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['filter'] !== 'undefined') {
      queryParameters['filter'] = parameters['filter'];
    }

    queryParameters['filter'] = this.transformParameter(
      queryParameters['filter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

     */
  getNewsAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} parameters.body - 
     */
  putNewsAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

     */
  deleteNewsAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change article type.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} parameters.body - 
     */
  putArticleTypeAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}/type';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in product variant.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} parameters.body - 
     */
  postTitleImageAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}/titleImage';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create image in product variant.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} parameters.body - 
     */
  postArticleImageAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}/articleImage';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete image in news.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} fullPath - File path

     */
  deleteNewsArticleImageAction(newsId, fullPath, parameters = {}) {
    let path = '/admin/news/{newsId}/articleImage/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create news attachment.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} parameters.lang - Language of attachment
       * @param {} parameters.body - 
     */
  postNewsAttachmentAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}/attachments';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['lang'] === 'undefined') {
      throw Error('Missing required parameter: lang');
    }

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete news attachment

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

       * @param {} fullPath - File path

     */
  deleteNewsAttachmentAction(newsId, fullPath, parameters = {}) {
    let path = '/admin/news/{newsId}/attachments/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create news copy.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} newsId - ID of news article

     */
  postNewsCopyAction(newsId, parameters = {}) {
    let path = '/admin/news/{newsId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{newsId}', newsId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get references.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.country - Country
       * @param {} parameters.state - State
       * @param {} parameters.city - City
       * @param {} parameters.year - Year
     */
  getReferencesAction(parameters = {}) {
    let path = '/admin/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['city'] !== 'undefined') {
      queryParameters['city'] = parameters['city'];
    }

    queryParameters['city'] = this.transformParameter(queryParameters['city']);

    if (typeof parameters['year'] !== 'undefined') {
      queryParameters['year'] = parameters['year'];
    }

    queryParameters['year'] = this.transformParameter(queryParameters['year']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create reference.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postReferencesAction(parameters = {}) {
    let path = '/admin/references';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get reference details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
     */
  getReferenceAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update reference.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
       * @param {} parameters.body - 
     */
  putReferenceAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove reference.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
     */
  deleteReferenceAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get reference images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
     */
  getReferenceImagesAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create reference image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
       * @param {} parameters.body - 
     */
  postReferenceImageAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create reference.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
       * @param {} fullPath - File path

     */
  deleteReferenceImageAction(referenceId, fullPath, parameters = {}) {
    let path = '/admin/references/{referenceId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get icons.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getIconsAction(parameters = {}) {
    let path = '/admin/icons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations objects.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by object name
       * @param {} parameters.sorter - Sorter
     */
  getInspirationsObjectsAction(parameters = {}) {
    let path = '/admin/inspirations-objects';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspirations object.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postInspirationsObjectAction(parameters = {}) {
    let path = '/admin/inspirations-objects';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations object details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of inspiration
     */
  getInspirationsObjectAction(inspirationsObjectId, parameters = {}) {
    let path = '/admin/inspirations-objects/{inspirationsObjectId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update inspirations object.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of inspirations object
       * @param {} parameters.body - 
     */
  putInspirationsObjectAction(inspirationsObjectId, parameters = {}) {
    let path = '/admin/inspirations-objects/{inspirationsObjectId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of inspirations object
     */
  deleteInspirationsObjectAction(inspirationsObjectId, parameters = {}) {
    let path = '/admin/inspirations-objects/{inspirationsObjectId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Inspiration images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of Inspirations object
     */
  getInspirationsObjectImagesAction(inspirationsObjectId, parameters = {}) {
    let path = '/admin/inspirations-objects/{inspirationsObjectId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspiration image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of Inspirations object
       * @param {} parameters.body - 
     */
  postInspirationsObjectImageAction(inspirationsObjectId, parameters = {}) {
    let path = '/admin/inspirations-objects/{inspirationsObjectId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationsObjectId - ID of Inspirations object
       * @param {} fullPath - File path

     */
  deleteInspirationsObjectImageAction(
    inspirationsObjectId,
    fullPath,
    parameters = {},
  ) {
    let path =
      '/admin/inspirations-objects/{inspirationsObjectId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationsObjectId}', inspirationsObjectId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspirations.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by inspiration name
       * @param {} parameters.state - State
       * @param {} parameters.sorter - Sorter
     */
  getInspirationsAction(parameters = {}) {
    let path = '/admin/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create reference.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postInspirationsAction(parameters = {}) {
    let path = '/admin/inspirations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get inspiration details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
     */
  getInspirationAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
       * @param {} parameters.body - 
     */
  putInspirationAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
     */
  deleteInspirationAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspiration copy.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
     */
  postInspirationCopyAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}/copy';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get Inspiration images.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of Inspiration
     */
  getInspirationImagesAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspiration image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of Inspiration
       * @param {} parameters.body - 
     */
  postInspirationImageAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}/images';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
       * @param {} fullPath - File path

     */
  deleteInspirationImageAction(inspirationId, fullPath, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}/images/{fullPath}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    path = path.replace('{fullPath}', fullPath);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change inspiration publication.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} inspirationId - ID of inspiration
       * @param {} parameters.body - 
     */
  putInspirationPublicationAction(inspirationId, parameters = {}) {
    let path = '/admin/inspirations/{inspirationId}/publication';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{inspirationId}', inspirationId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Change reference publication.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} referenceId - ID of reference
       * @param {} parameters.body - 
     */
  putReferencePublicationAction(referenceId, parameters = {}) {
    let path = '/admin/references/{referenceId}/publication';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{referenceId}', referenceId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product groups.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quick search by group name
       * @param {} parameters.sorter - Sorter
     */
  getProductsGroupsAction(parameters = {}) {
    let path = '/admin/products-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postProductGroupAction(parameters = {}) {
    let path = '/admin/products-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product group detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productGroupId - ID of product group

     */
  getProductGroupAction(productGroupId, parameters = {}) {
    let path = '/admin/products-groups/{productGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productGroupId}', productGroupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productGroupId - ID of product group

       * @param {} parameters.body - 
     */
  putProductGroupAction(productGroupId, parameters = {}) {
    let path = '/admin/products-groups/{productGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productGroupId}', productGroupId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productGroupId - ID of product group

     */
  deleteProductGroupAction(productGroupId, parameters = {}) {
    let path = '/admin/products-groups/{productGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productGroupId}', productGroupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get customer margins.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quick search by group name
       * @param {} parameters.companyId - Id of company
       * @param {} parameters.sorter - Sorter
     */
  getCustomerMarginsAction(parameters = {}) {
    let path = '/admin/customer-margins';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['companyId'] !== 'undefined') {
      queryParameters['companyId'] = parameters['companyId'];
    }

    queryParameters['companyId'] = this.transformParameter(
      queryParameters['companyId'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create customer margin.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postCustomerMarginAction(parameters = {}) {
    let path = '/admin/customer-margins';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product group detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerId - ID od customer

     */
  getCustomerMarginAction(customerId, parameters = {}) {
    let path = '/admin/customer-margins/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerId - ID od customer

       * @param {} parameters.body - 
     */
  putCustomerMarginAction(customerId, parameters = {}) {
    let path = '/admin/customer-margins/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove inspiration.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} customerId - ID od customer

     */
  deleteCustomerMarginAction(customerId, parameters = {}) {
    let path = '/admin/customer-margins/{customerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{customerId}', customerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get purchase prices.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getPurchaseSettingsAction(parameters = {}) {
    let path = '/admin/purchase-prices-settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create product group.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putPurchaseSettingsAction(parameters = {}) {
    let path = '/admin/purchase-prices-settings';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of Cash Register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.firm - Company ID
       * @param {} parameters.limit - Limit for paginated results
       * @param {} parameters.offset - Offset for paginated results
     */
  getCashRegistersAction(parameters = {}) {
    let path = '/admin/cash-registers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Return cash register with detail preview.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashRegisterId - ID of cash register

       * @param {} parameters.firm - Company ID
     */
  getCashRegisterAction(cashRegisterId, parameters = {}) {
    let path = '/admin/cash-registers/{cashRegisterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cashRegisterId}', cashRegisterId);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['firm'] === 'undefined') {
      throw Error('Missing required parameter: firm');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cash register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashRegisterId - ID of cash register

       * @param {} parameters.firm - Company ID
       * @param {} parameters.body - 
     */
  putCashRegisterAction(cashRegisterId, parameters = {}) {
    let path = '/admin/cash-registers/{cashRegisterId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cashRegisterId}', cashRegisterId);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['firm'] === 'undefined') {
      throw Error('Missing required parameter: firm');
    }

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cash register password.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashRegisterId - ID of cash register

       * @param {} parameters.body - 
     */
  putCashRegisterPasswordAction(cashRegisterId, parameters = {}) {
    let path = '/admin/cash-registers/{cashRegisterId}/password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cashRegisterId}', cashRegisterId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create or update price for Cash Register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.override - Override sales price if exists
       * @param {} parameters.body - 
     */
  postCashRegisterPricesAction(parameters = {}) {
    let path = '/admin/cash-registers/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['override'] !== 'undefined') {
      queryParameters['override'] = parameters['override'];
    }

    queryParameters['override'] = this.transformParameter(
      queryParameters['override'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get product sales price price for Cash Register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.firm - Company ID
     */
  getCashRegisterPricesAction(productId, parameters = {}) {
    let path = '/admin/cash-registers/products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['firm'] === 'undefined') {
      throw Error('Missing required parameter: firm');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete price for Cash Register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - ID od product

       * @param {} parameters.firm - Company ID
     */
  deleteCashRegisterPricesAction(productId, parameters = {}) {
    let path = '/admin/cash-registers/products/{productId}/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['firm'] === 'undefined') {
      throw Error('Missing required parameter: firm');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Import prices for Cash Register.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.override - Override sales prices if exists
       * @param {} parameters.body - 
     */
  postCashRegisterPricesImportAction(parameters = {}) {
    let path = '/admin/cash-registers/import/prices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['override'] !== 'undefined') {
      queryParameters['override'] = parameters['override'];
    }

    queryParameters['override'] = this.transformParameter(
      queryParameters['override'],
    );

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of cash register cashiers.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by name
       * @param {} parameters.country - search by country
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.shopId - shopId
     */
  getCashiersAction(parameters = {}) {
    let path = '/admin/cashiers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shopId'] = parameters['shopId'];
    }

    queryParameters['shopId'] = this.transformParameter(
      queryParameters['shopId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new Cashier.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postCashierAction(parameters = {}) {
    let path = '/admin/cashiers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of a cashier.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashierId - ID of cashier
     */
  getCashierAction(cashierId, parameters = {}) {
    let path = '/admin/cashier/{cashierId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cashierId}', cashierId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cashier.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashierId - ID of cashier
       * @param {} parameters.body - 
     */
  putCashierAction(cashierId, parameters = {}) {
    let path = '/admin/cashier/{cashierId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cashierId}', cashierId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete cashier.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashierId - ID of cashier
     */
  deleteCashierAction(cashierId, parameters = {}) {
    let path = '/admin/cashier/{cashierId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{cashierId}', cashierId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update cashier.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cashierId - ID of cashier
       * @param {} parameters.body - 
     */
  putCashierEanPasswordAction(cashierId, parameters = {}) {
    let path = '/admin/cashier/{cashierId}/ean';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{cashierId}', cashierId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of brands portfolio.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by name
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.portfolioId - portfolioId
     */
  getPortfolioBrandsAction(parameters = {}) {
    let path = '/admin/portfolios';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['portfolioId'] !== 'undefined') {
      queryParameters['portfolioId'] = parameters['portfolioId'];
    }

    queryParameters['portfolioId'] = this.transformParameter(
      queryParameters['portfolioId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create new Portfolio.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postPortfolioBrandAction(parameters = {}) {
    let path = '/admin/portfolios';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get detail of brand portfolio.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} portfolioId - ID of portfolio
     */
  getPortfolioBrandAction(portfolioId, parameters = {}) {
    let path = '/admin/portfolio/{portfolioId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{portfolioId}', portfolioId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update portfolio.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} portfolioId - ID of portfolio
       * @param {} parameters.body - 
     */
  putPortfolioBrandAction(portfolioId, parameters = {}) {
    let path = '/admin/portfolio/{portfolioId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{portfolioId}', portfolioId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get favorit productslist.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getFavoritProductsAction(parameters = {}) {
    let path = '/admin/favorit-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Save favorite products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putFavoritProductsAction(parameters = {}) {
    let path = '/admin/favorit-products';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
     */
  getCatalogProductsExportAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/products-export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get types of tinted products.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getTintingTypesAction(parameters = {}) {
    let path = '/admin/tinting-types';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get stocks.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.lang - Filter by lang
       * @param {} parameters.app - Filter by app
     */
  getStocksAction(parameters = {}) {
    let path = '/admin/stocks';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['app'] !== 'undefined') {
      queryParameters['app'] = parameters['app'];
    }

    queryParameters['app'] = this.transformParameter(queryParameters['app']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get colorcard shade categorized.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} colorcardId - ID od colorcard

     */
  getColorcardShadesCategorizations(colorcardId, parameters = {}) {
    let path = '/admin/colorcards/{colorcardId}/shade-categorization';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{colorcardId}', colorcardId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Put multiple shade categories.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} colorcardId - ID od colorcard

       * @param {} parameters.body - 
     */
  putShadesCategorization(colorcardId, parameters = {}) {
    let path = '/admin/colorcards/{colorcardId}/shade-categorization';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add new shade category.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} colorcardId - ID od colorcard

       * @param {} parameters.body - 
     */
  putShadeCategorization(colorcardId, parameters = {}) {
    let path = '/admin/colorcards/{colorcardId}/shade-categorization-update';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{colorcardId}', colorcardId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get service fees

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.type - type of service fee
       * @param {} parameters.lang - lang of service fee
     */
  getServiceFeesAction(parameters = {}) {
    let path = '/admin/service-fees';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create service fee.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postServiceFeeAction(parameters = {}) {
    let path = '/admin/service-fees';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get service detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} feeId - ID of service fee

     */
  getServiceFeeAction(feeId, parameters = {}) {
    let path = '/admin/service-fees/{feeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{feeId}', feeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update service fee.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} feeId - ID of service fee

       * @param {} parameters.body - 
     */
  putServiceFeeAction(feeId, parameters = {}) {
    let path = '/admin/service-fees/{feeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{feeId}', feeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete service.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} feeId - ID of service fee

     */
  deleteServiceFeeAction(feeId, parameters = {}) {
    let path = '/admin/service-fees/{feeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{feeId}', feeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get notifications.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Qickseach by product number or name
       * @param {} parameters.sorter - Sorter
     */
  getNotificationsAction(parameters = {}) {
    let path = '/admin/notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postNotificationAction(parameters = {}) {
    let path = '/admin/notifications';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get notification details.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} notificationId - ID of notification
     */
  getNotificationAction(notificationId, parameters = {}) {
    let path = '/admin/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} notificationId - ID of notification
       * @param {} parameters.body - 
     */
  putNotificationAction(notificationId, parameters = {}) {
    let path = '/admin/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{notificationId}', notificationId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Remove notification.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} notificationId - ID of notification
     */
  deleteNotificationAction(notificationId, parameters = {}) {
    let path = '/admin/notifications/{notificationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{notificationId}', notificationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get sales products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch for products in sale.
       * @param {} parameters.firm - Firm filter for products in sale.
       * @param {} parameters.active - Filtering products packages according to active status (from dates of publication).
       * @param {} parameters.type - Type of sale
       * @param {} parameters.sorter - Sorter for sale products.
       * @param {} parameters.catalogProductId - Filter by catalog product Id
     */
  getSaleProductsAction(parameters = {}) {
    let path = '/admin/catalog-products/sale';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['firm'] !== 'undefined') {
      queryParameters['firm'] = parameters['firm'];
    }

    queryParameters['firm'] = this.transformParameter(queryParameters['firm']);

    if (typeof parameters['active'] !== 'undefined') {
      queryParameters['active'] = parameters['active'];
    }

    queryParameters['active'] = this.transformParameter(
      queryParameters['active'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['catalogProductId'] !== 'undefined') {
      queryParameters['catalogProductId'] = parameters['catalogProductId'];
    }

    queryParameters['catalogProductId'] = this.transformParameter(
      queryParameters['catalogProductId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.type - Type of sale
       * @param {} parameters.body - 
     */
  postSaleProductsAction(parameters = {}) {
    let path = '/admin/catalog-products/sale';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putSaleProductsDeactivateAction(parameters = {}) {
    let path = '/admin/catalog-products/sale';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update sales products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putSalesProductAction(parameters = {}) {
    let path = '/admin/catalog-products/sales';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get sales product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} saleProductId - ID od product

     */
  getSaleProductAction(saleProductId, parameters = {}) {
    let path = '/admin/catalog-products/sale/{saleProductId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{saleProductId}', saleProductId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update product.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} saleProductId - ID od product

       * @param {} parameters.body - 
     */
  putSaleProductAction(saleProductId, parameters = {}) {
    let path = '/admin/catalog-products/sale/{saleProductId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{saleProductId}', saleProductId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.lang - Filter products according to lang
       * @param {} parameters.q - Quicksearch by product number or name
       * @param {} parameters.catalogProductsIds - 
       * @param {} parameters.allCountries - 
     */
  getCatalogProductsVariantsAction(parameters = {}) {
    let path = '/admin/catalog-products/variants';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['catalogProductsIds'] !== 'undefined') {
      queryParameters['catalogProductsIds'] = parameters['catalogProductsIds'];
    }

    queryParameters['catalogProductsIds'] = this.transformParameter(
      queryParameters['catalogProductsIds'],
    );

    if (typeof parameters['allCountries'] !== 'undefined') {
      queryParameters['allCountries'] = parameters['allCountries'];
    }

    queryParameters['allCountries'] = this.transformParameter(
      queryParameters['allCountries'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get catalog products security alerts.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.type - Filter alerts by type
       * @param {} parameters.code - Filter alerts by code
       * @param {} parameters.q - Quicksearch
     */
  getSecurityAlertsActions(companyId, parameters = {}) {
    let path = '/admin/{companyId}/catalog-products/alerts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} companyId - Shortcut of location
       * @param {} parameters.q - Query
       * @param {} parameters.type - Filter by type
     */
  getMultimediaAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/get-multimedia';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get multimedia file.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} multimediaId - Multimedia ID
     */
  getMultimediaByIdAction(companyId, multimediaId, parameters = {}) {
    let path = '/admin/{companyId}/get-multimedia/{multimediaId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{multimediaId}', multimediaId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create PDF template

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postPdfTemplateAction(parameters = {}) {
    let path = '/admin/{companyId}/pdf-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get list of PDF templates.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by a product number or name
       * @param {} parameters.sorter - Sorter
     */
  getPdfTemplatesAction(parameters = {}) {
    let path = '/admin/{companyId}/pdf-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get pdf template detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pdfTemplateId - Id of template

     */
  getPdfTemplateAction(pdfTemplateId, parameters = {}) {
    let path = '/admin/{companyId}/pdf-template/{pdfTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{pdfTemplateId}', pdfTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update pdf template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pdfTemplateId - Id of template

       * @param {} parameters.body - 
     */
  putPdfTemplateAction(pdfTemplateId, parameters = {}) {
    let path = '/admin/{companyId}/pdf-template/{pdfTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{pdfTemplateId}', pdfTemplateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create copy of pdf template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} pdfTemplateId - Id of template

     */
  postPdfTemplateCopyAction(pdfTemplateId, parameters = {}) {
    let path = '/admin/{companyId}/pdf-template-copy/{pdfTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{pdfTemplateId}', pdfTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create email template

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postEmailTemplateAction(parameters = {}) {
    let path = '/admin/{companyId}/email-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by a product number or name
       * @param {} parameters.sorter - Sorter
     */
  getEmailTemplatesAction(parameters = {}) {
    let path = '/admin/{companyId}/email-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create copy of email template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} emailTemplateId - Id of template

     */
  postEmailTemplateCopyAction(emailTemplateId, parameters = {}) {
    let path = '/admin/{companyId}/email-template-copy/{emailTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{emailTemplateId}', emailTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Add attachment to email template

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} emailTemplateId - Id of template

       * @param {} parameters.body - 
     */
  postEmailTemplateAttachmentAction(
    companyId,
    emailTemplateId,
    parameters = {},
  ) {
    let path =
      '/admin/{companyId}/email-templates/{emailTemplateId}/attachment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{emailTemplateId}', emailTemplateId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete attachment from email template

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} emailTemplateId - Id of template

       * @param {} parameters.fullPath - Full path of attachment
     */
  deleteEmailTemplateAttachmentAction(
    companyId,
    emailTemplateId,
    parameters = {},
  ) {
    let path =
      '/admin/{companyId}/email-templates/{emailTemplateId}/attachment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{emailTemplateId}', emailTemplateId);

    if (typeof parameters['fullPath'] !== 'undefined') {
      queryParameters['fullPath'] = parameters['fullPath'];
    }

    queryParameters['fullPath'] = this.transformParameter(
      queryParameters['fullPath'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Download email template attachment

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} emailTemplateId - Id of template

       * @param {} parameters.fullPath - Full path of attachment
     */
  getEmailTemplateAttachmentDownloadAction(
    companyId,
    emailTemplateId,
    parameters = {},
  ) {
    let path =
      '/admin/{companyId}/email-templates/{emailTemplateId}/attachment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{emailTemplateId}', emailTemplateId);

    if (typeof parameters['fullPath'] !== 'undefined') {
      queryParameters['fullPath'] = parameters['fullPath'];
    }

    queryParameters['fullPath'] = this.transformParameter(
      queryParameters['fullPath'],
    );

    if (typeof parameters['fullPath'] === 'undefined') {
      throw Error('Missing required parameter: fullPath');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get email template detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} emailTemplateId - Id of template

     */
  getEmailTemplateAction(emailTemplateId, parameters = {}) {
    let path = '/admin/email-template/{emailTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{emailTemplateId}', emailTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update email template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} emailTemplateId - Id of template

       * @param {} parameters.body - 
     */
  putEmailTemplateAction(emailTemplateId, parameters = {}) {
    let path = '/admin/email-template/{emailTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{emailTemplateId}', emailTemplateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create email template

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postPresetEmailTemplateAction(parameters = {}) {
    let path = '/admin/preset-email-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get products.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by a product number or name
       * @param {} parameters.sorter - Sorter
     */
  getPresetEmailTemplatesAction(parameters = {}) {
    let path = '/admin/preset-email-templates/';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get email template detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} presetEmailTemplateId - Id of template

     */
  getPresetEmailTemplateAction(presetEmailTemplateId, parameters = {}) {
    let path = '/admin/preset-email-template/{presetEmailTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{presetEmailTemplateId}', presetEmailTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update email template.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} presetEmailTemplateId - Id of template

       * @param {} parameters.body - 
     */
  putPresetEmailTemplateAction(presetEmailTemplateId, parameters = {}) {
    let path = '/admin/preset-email-template/{presetEmailTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{presetEmailTemplateId}', presetEmailTemplateId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update helpdesk solvers

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putHelpdeskSolversAction(parameters = {}) {
    let path = '/admin/helpdesk-solvers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get helpdesk solvers.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getHelpdeskSolversAction(parameters = {}) {
    let path = '/admin/helpdesk-solvers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get security alerts list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.lang - Filter by lang
       * @param {} parameters.q - Quick search
       * @param {} parameters.sorter - Sorter
     */
  getSecurityAlertsAction(parameters = {}) {
    let path = '/admin/security-alerts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['lang'] !== 'undefined') {
      queryParameters['lang'] = parameters['lang'];
    }

    queryParameters['lang'] = this.transformParameter(queryParameters['lang']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create security alert.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postSecurityAlertAction(parameters = {}) {
    let path = '/admin/security-alerts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get security alert detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} securityAlertId - 
     */
  getSecurityAlertAction(securityAlertId, parameters = {}) {
    let path = '/admin/security-alert/{securityAlertId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{securityAlertId}', securityAlertId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update security alert.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} securityAlertId - 
       * @param {} parameters.body - 
     */
  putSecurityAlertAction(securityAlertId, parameters = {}) {
    let path = '/admin/security-alert/{securityAlertId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{securityAlertId}', securityAlertId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete security alert.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} securityAlertId - 
     */
  deleteSecurityAlertAction(securityAlertId, parameters = {}) {
    let path = '/admin/security-alert/{securityAlertId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{securityAlertId}', securityAlertId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Import products list on external stock.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  postExternalStocksImportAction(parameters = {}) {
    let path = '/admin/external-stock/import';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Export products list to csv

     * @method
     * @param {object} parameters - method options and parameters
     */
  getExportProductsExternalStocksAction(parameters = {}) {
    let path = '/admin/external-stock/export';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update products on external stock.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putProductsStockStatusAction(parameters = {}) {
    let path = '/admin/external-stock/state';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    if (typeof parameters['body'] === 'undefined') {
      throw Error('Missing required parameter: body');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get company base info.

     * @method
     * @param {object} parameters - method options and parameters
     */
  getCompanyBaseInfoAction(parameters = {}) {
    let path = '/admin/company-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Edit company base info.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.body - 
     */
  putCompanyBaseInfoAction(parameters = {}) {
    let path = '/admin/company-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get employers list.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.limit - Limit parameter
       * @param {} parameters.offset - Offset parameter
       * @param {} parameters.q - Quicksearch by name
       * @param {} parameters.sorter - Sorter
       * @param {} parameters.publish - Publish filter
     */
  getEmployersAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/employers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['publish'] !== 'undefined') {
      queryParameters['publish'] = parameters['publish'];
    }

    queryParameters['publish'] = this.transformParameter(
      queryParameters['publish'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Save new employer.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} parameters.body - 
     */
  postEmployerAction(companyId, parameters = {}) {
    let path = '/admin/{companyId}/employers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get employer detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} employerId - 
     */
  getEmployerAction(companyId, employerId, parameters = {}) {
    let path = '/admin/{companyId}/employers/{employerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{employerId}', employerId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Edit employer detail.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} employerId - 
       * @param {} parameters.body - 
     */
  putEmployerAction(companyId, employerId, parameters = {}) {
    let path = '/admin/{companyId}/employers/{employerId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{employerId}', employerId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Create employer image.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Shortcut of location
       * @param {} employerId - 
       * @param {} parameters.body - 
     */
  postEmployerImageAction(companyId, employerId, parameters = {}) {
    let path = '/admin/{companyId}/employers/{employerId}/image';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    path = path.replace('{employerId}', employerId);

    if (typeof parameters['body'] !== 'undefined') {
      form['body'] = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Get translation entities log.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} entityId - ID of entity
       * @param {} attributeId - ID of attribute
       * @param {} parameters.entityItemIds - Entity item ids.
     */
  getEntityTranslationsChangesLogAction(
    entityId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entityId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{entityId}', entityId);

    path = path.replace('{attributeId}', attributeId);

    if (typeof parameters['entityItemIds'] !== 'undefined') {
      queryParameters['entity_item_ids'] = parameters['entityItemIds'];
    }

    queryParameters['entity_item_ids'] = this.transformParameter(
      queryParameters['entity_item_ids'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Update translation entities log.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} entityId - ID of entity
       * @param {} attributeId - ID of attribute
       * @param {} parameters.body - 
     */
  putEntityTranslationsChangesLogAction(
    entityId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entityId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{entityId}', entityId);

    path = path.replace('{attributeId}', attributeId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Delete translation entities log.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} entityId - ID of entity
       * @param {} attributeId - ID of attribute
       * @param {} parameters.entityItemId - Entity item ids.
     */
  deleteEntityTranslationsChangesLogAction(
    entityId,
    attributeId,
    parameters = {},
  ) {
    let path = '/admin/entity/change-log/{entityId}/{attributeId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{entityId}', entityId);

    path = path.replace('{attributeId}', attributeId);

    if (typeof parameters['entityItemId'] !== 'undefined') {
      queryParameters['entity_item_id'] = parameters['entityItemId'];
    }

    queryParameters['entity_item_id'] = this.transformParameter(
      queryParameters['entity_item_id'],
    );

    if (typeof parameters['entityItemId'] === 'undefined') {
      throw Error('Missing required parameter: entityItemId');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  openapiJson(parameters = {}) {
    let path = '/openapi.json';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };
